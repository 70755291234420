import { IsServer } from 'util/browser'
import { sendGAEvent } from '@next/third-parties/google'

export const SendGAEvent = (
  category: string,
  action: string,
  label?: string
) => {
  if (IsServer() || !category || !action) return

  sendGAEvent('event', `${category}_${action}${label ? `_${label}` : ''}`)
}
